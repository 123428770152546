<template>
  <div class="sp-prerequisite">
    <div class="sp-prerequisite__content">
      <img
        src="https://files.lxp.academy.who.int/learning-experience-platform/vle/pre-requisite/locked.svg"
        alt="locked"
      />
      <h5>{{ $t("vle.prerequisite.locked_content") }}</h5>
      <i18next path="vle.prerequisite.locked_content_description" tag="p">
        <strong>
          {{ getCurrentSequence.prereq_meta_info.display_name }}
        </strong>
      </i18next>
      <div class="sp-prerequisite__content--btns">
        <LxpButton
          variant="outline-primary"
          size="default"
          block
          @click="nextUnit"
          v-if="showSkip"
          >{{ $t("general.skip") }}</LxpButton
        >
        <LxpButton
          variant="primary"
          size="default"
          block
          @click="completePrerequisite"
          >{{ $t("vle.prerequisite.complete_prerequisite") }}</LxpButton
        >
      </div>
      <div class="sp-prerequisite__content--note">
        <img
          src="https://files.lxp.academy.who.int/learning-experience-platform/vle/pre-requisite/question.svg"
          alt="question"
        />
        <i18next path="vle.prerequisite.note" tag="span">
          <a href="mailto:whoasupport@who.int" target="_blank">
            {{ $t("landing_page.footer.faqs") }}
          </a>
        </i18next>
      </div>
    </div>
  </div>
</template>
<script>
import { LxpButton } from "didactica";
import { mapGetters } from "vuex";
import nextUnit from "@/components/SelfPaced/mixins/nextUnit.js";
export default {
  mixins: [nextUnit],
  components: {
    LxpButton
  },
  computed: {
    ...mapGetters(["getCurrentSequence", "isLastUnit", "isLastSequence"]),
    showSkip() {
      return this.isLastUnit && this.isLastSequence;
    }
  },
  methods: {
    completePrerequisite() {}
  }
};
</script>
<style lang="scss" scoped>
.sp-prerequisite {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(229, 241, 255, 0.14);
  backdrop-filter: blur(12px);
  width: 100%;
  @include flex-horizontal-center;
  .sp-prerequisite__content {
    border-radius: 24px;
    background: $brand-neutral-0;
    box-shadow: 2px 3.5px 20px 3px rgba(0, 129, 255, 0.15);
    padding: 32px 48px 24px;
    gap: 24px;
    width: 700px;
    @include center;
    h5 {
      @include h5($brand-neutral-900);
      margin-bottom: 0;
    }
    p {
      @include body-regular;
      margin-bottom: 0;
    }
    .sp-prerequisite__content--btns {
      @include flex-horizontal-center;
      gap: 16px;
      width: 100%;
      :deep .btn {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
    .sp-prerequisite__content--note {
      display: flex;
      justify-content: flex-start;
      gap: 16px;
      span {
        @include label-large;
        text-align: left;
        a {
          font-weight: 500;
          color: $brand-primary-400;
        }
      }
    }
  }
}
</style>
